.web ion-content.route-delivery {
	--ion-color-base: transparent;
	/*--background: transparent url(../../assets/images/page-05.jpg) repeat-y 0 0 / 100% auto;*/
}
.delivery-wrapper {
	overflow: auto;
}
.delivery-additional-restaurant-information {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.delivery-additional-restaurant-information * {
	color: var(--ion-color-primary);
	/* font-weight: bold; */
}
