ion-content div.dash-layout {
	background-color: var(--okx-background-color) ;
	background-repeat: no-repeat;
	background-position: 50% 0;
	background-size: cover;
}

.dash-card {
	position: absolute;
	left: 30px;
	right: 30px;
	bottom: 0;
	z-index: 1;
	--background: var(--ion-color-white);
	border-radius: var(--ion-card-radius);
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
	padding-bottom: 15%;

	box-shadow: 0 0 32px rgba(0, 0, 0, 0.25);
	margin-top: 0;
	margin-bottom: 0;
}
.dash-card .title{
	color: var(--okx-box-wrapper-color);
}
.dash-card-image-wrapper{
	width: 100%;
	position: absolute;
	left: 0;
}
.dash-card-image-wrapper img{
	width: 100%;
	height: auto;
	max-height: 115px;
	margin-top: 20px;

}
.dash-card *{
	--color: var(--okx-box-wrapper-color);
}
.dash-menu {
	padding: 0 64px !important;
}

.dash-menu ion-item {
	--color: var(--ion-color-primary);
	--background: trasparent;
	--background-hover: trasparent;
	--min-height: 32px;
}

.dash-card ion-label {
	font-family: var(--ion-font-family-bold);  
	font-size: var(--okx-bold-font-size) !important;
	font-weight: bold;

}

.dash-menu ion-label {
	text-align: center;
	font-family: var(--ion-font-family-bold) !important;
	font-size: var(--okx-bold-font-size) !important;

}

.web .static.route-dashboard {
	background: transparent none;
}

.web ion-content.route-dashboard div.dash-layout.absolute-content {
	background: transparent none;
}

.web ion-content.route-dashboard div.dash-layout.absolute-content:after {
	display: none;
}
ion-content.route-dashboard div.dash-layout.absolute-content:after {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	display: block;
	content: '';
	background-image: linear-gradient(#fff, rgba(255, 255, 255, 0) 40%);
}

.web ion-content div.dash-layout::before {
	background-image: none;
}

.web .dash-card {
	top: var(--okx-bsmodal-top);
	bottom: auto;
	left: auto;
	right: auto;
	position: relative;
	width: 50%;
	max-width: var(--okx-bsmodal-width-md);
	min-width: 280px;
	padding-top: 24px;
	padding-bottom: 80px;
	margin-left: auto;
	margin-right: auto;
	border-radius: var(--okx-box-radius);
	background-position: 50% 108%;
	background-size: 120px auto;
}

.web .dash-menu ion-item {
	--color: var(--ion-color-white);
	--background: var(--ion-color-black);
	margin-bottom: 20px;
	border-radius: var(--okx-small-radius);
}

.dashboard-card:last-of-type {
	margin: 0;
}
.dashboard-card {
	height: 150px;
	border-radius: var(--okx-box-radius);
	background-position: center;
	background-size: cover;
	margin-bottom: 20px;
	position: relative;
	overflow: hidden;
	padding: 5px 20px;
	display: flex;
	align-items: flex-end;
}
.dashboard-card::before {
	content: '';
	display: block;
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	height: 100%;
	z-index: 0;
	background-image: var(--okx-dashboard-card-background-gradient)
}

.dashboard-cards-wrapper {
	padding: var(--ion-padding);
	overflow: hidden;
	margin-top: -140px;
}
.dashboard-content .dashboard-card .subtitle {
	color: var(--okx-dashboard-card-color) !important;
	z-index: 5;
}
.dashboard-card > * {
	z-index: 5;
}
.dashboard-header {
	padding: 10px var(--ion-padding) 170px;
	position: relative;
	border-bottom-left-radius: 20px;
	border-bottom-right-radius: 20px;
}

.dashboard-header * {
	color: var(--okx-dashboard-color) !important;
}
.colored-header.route-dashboard ion-toolbar.primary-toolbar {
	background-color: transparent;
}
.dashboard-header .title {
	font-size: var(--okx-dashboard-title-font-size)
}

.colored-header.route-dashboard ion-toolbar.primary-toolbar,
.dashboard-header {
	background-color: var(--okx-dashboard-background);
}

.white-header.route-dashboard ion-toolbar.primary-toolbar {
	background-color: var(--ion-color-white);
}

ion-content.route-dashboard:before {
	position: fixed;
	content: '';
	top: 0;
	height: 50%;
	width: 100%;
	z-index: -1;
}

.dashboard-content.dashboard-content-dark * {
	color: var(--ion-color-dark) !important;
}
.dashboard-content.dashboard-content-white * {
	color: var(--ion-color-white) !important;
}
ion-card-title{
	font-size: 28px;
	font-weight: bold;
}