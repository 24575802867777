.payment-history-header div {
	display: flex;
	align-items: center;
}
.payment-history-header ion-icon {
	height: 12px;
	width: 12px;
	margin-left: 5px;
}
.payment-history-wrapper {
	padding: 5px 0;
}
