.bill-pay-service-charge-wrapper {
  display: flex;
  flex-direction: column;
  padding: 15px;
  border-radius: var(--okx-box-radius);
  background-color: var(--okx-box-wrapper-background);
}
.bill-pay-service-charge-wrapper > div {
  display: flex;
}
.bill-pay-service-charge-wrapper ion-button {
  flex: 1;
}
.bill-pay-service-charge-wrapper * {
  color: var(--okx-box-wrapper-color);
}
.bill-pay-service-charge-wrapper ion-button {
  --ion-color-base: var(--okx-box-wrapper-color) !important;
}
.ion-color-secondary.bill-pay-service-charge-button.is-active {
  --ion-color-base: var(--ion-color-primary) !important;
  --ion-color-contrast: var(--ion-color-primary-contrast) !important;
  --border-color: var(--ion-color-primary);
}
ion-button.ion-color-secondary.bill-pay-service-charge-button {
  --ion-color-base: transparent !important;
  --ion-color-contrast: var(--okx-box-wrapper-color) !important;
  --border-style: solid;
  --border-width: 1px;
  --border-color: var(--okx-box-wrapper-color);
  height: 35px;
  margin-bottom: 0;
}
.custom-service-charge-modal .modal-wrapper {
  padding: var(--ion-padding);
  height: 260px;
}
.custom-service-charge-modal .box-wrapper {
  padding: 5px;
}
ion-modal.modal-classic.custom-service-charge-modal {
  --width: 70% !important;
  --height: 350px !important;
  --border-radius:var(--okx-box-radius)

}
.web ion-modal.modal-classic.custom-service-charge-modal {
    --width: 350px !important;

  }
  