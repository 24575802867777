ion-button.link.link-centered {
	margin: 0 auto;
}

.web .route-checkout .ion-color-primary {
	margin-bottom: 10px !important;
}

.web .route-checkout {
	--background: transparent none;
	--ion-color-white: transparent;
}

.web .route-checkout .ion-padding .absolute-content {
	padding: var(--ion-padding);
}

.web .route-checkout .flex-min ion-button:first-child {
	margin-bottom: 10px !important;
}

.web .route-checkout .paymentRequestButton {
	padding: 0px;
}

.paymentRequestButton {
	margin-top: 10px;
	padding: 0 2px;
}

.paymentRequestButtonCardAdd {
	margin-bottom: 10px;
	padding: 0 2px;
}

/* .mbsc-select-input.mbsc-control {
  display: none !important;
} */
.delivery-time-modal .modal-wrapper {
	height: 70%;
	width: 90%;
}
.web .delivery-time-modal .modal-wrapper {
	width: 500px;
}
.delivery-time-modal .mbsc-select-input.mbsc-control {
	display: none !important;
}

.checkout-card {
	margin-bottom: 15px;
}
.checkout-card ion-icon {
	height: 35px;
	width: 35px;
	color: var(--okx-box-wrapper-color);
	position: absolute;
	left: 0;
}
.checkout-card.item-radio-checked {
	background-color: var(--ion-color-black) !important;
	color: var(--ion-color-primary-contrast);
}
.checkout-card.item-radio-checked * {
	color: var(--ion-color-primary-contrast) !important;
}
.checkout-card.item-radio-checked ion-icon {
	color: var(--ion-color-primary-contrast);
}
.checkout-card ion-radio {
	width: 40px;
	opacity: 0;
}
.pay-buttons-wrapper iframe {
	position: inherit !important;
}