.web ion-content.route-split-bill {
	width: 100%;
	height: calc(100vh - 79px);
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
	overflow-y: hidden;
}
.table-members-wrapper {
	padding: 0;
}

.table-members-wrapper .box-wrapper .mbsc-select-input {
	background: transparent;
	margin: 0;
	border-color: transparent;
}
.table-members-wrapper .box-wrapper {
	height: 50px;
	display: flex;
	margin: 0;
	padding: 10px;
}
.table-members-wrapper .normal-text {
	display: block;
	margin-bottom: 5px;
}
.route-split-bill ion-grid {
	margin-bottom: 0;
}
.table-members-wrapper ion-col:first-child {
	padding-left: 0;
}
.table-members-wrapper ion-col:last-child {
	padding-right: 0;
}
