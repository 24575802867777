.validate-button .validate-status {
	background-color: var(--ion-color-danger);
	color: var(--ion-color-danger-contrast);
	border-radius: 15px;
	padding: 5px;
	width: 120px;
	display: inline-block;
	text-align: center;
	margin-right: 10px;
	user-select: none;
}
.validate-button.valid .validate-status {
	background-color: var(--ion-color-success);
	color: var(--ion-color-success-contrast);
}
.web .validate-button .validate-status {
	padding: 5px;
}
.validate-button {
	padding: 0;
}
.validate-button ion-col {
	padding: 0;
}
