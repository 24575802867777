.cards-list-card-image ion-icon {
	height: 30px;
	width: 30px;
	margin-right: 15px;
}
.box-content ion-label {
	margin: 0 !important;
}
.cards-list-card ion-button {
	--background: transparent !important;
}
.cards-list-card {
	margin-bottom: 15px;
}
.cards-list-card ion-button ion-icon {
	height: 25px;
	width: 25px;
	color: var(--ion-color-danger);
}
.no-cards-box {
	background-color: transparent !important;
	border: 2px dashed var(--okx-box-wrapper-color);
	border-radius: var(--okx-box-radius);
	opacity: 0.5;
	display: flex;
	padding: var(--ion-padding);
	/* align-content: center; */
	align-items: center;
}
.no-cards-box * {
	color: var(--okx-box-wrapper-color);
}
.order-summary-card {
	margin-bottom: 15px;
}
.card-icon{
	height: 30px;
	width: 30px;
	color: var(--okx-box-wrapper-color);
	position: absolute;
}
.cards-list-card:has(.radio-checked) {
	background-color: var(--ion-color-primary) !important;
	color: var(--ion-color-primary-contrast)
}
.cards-list-card:has(.radio-checked) * ,.cards-list-card:has(.radio-checked) ion-icon{
	color: var(--ion-color-primary-contrast) !important;
}

.order-summary-card ion-radio {
	width: 30px;
	opacity: 0;
}
.remove-card-icon{
  right: 0;
  width: 25px;
  height: 25px;
}

