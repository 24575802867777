.error-page-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	padding: var(--ion-padding);
	height: 100%;
}
.error-page-container * {
	color: var(--ion-color-dark) !important;
}
