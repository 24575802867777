.order-status {
	display: inline-block;
	padding: 2px 4px;
	border: 1px solid var(--ion-color-black);
	color: var(--ion-color-black);
	font-size: 9px;
	text-transform: uppercase;
}

.route-order-completed .okx-box,
.route-order-completed .okx-box-header {
	border-color: var(--ion-color-secondary);
	border-width: 0px;
}

.order-details {
	/* background: transparent url(../../assets/images/page-04.jpg) no-repeat 100% 100%;
	background-size: 100% auto; */
	z-index: 1;
	overflow-y: auto;
}

/* ion-content.route-order-completed::after {
	position: absolute;
	left: 0; top: 0; right: 0; bottom: 0;
	display: block;
	content: '';
	background-image: linear-gradient(#fff 65%, rgba(255,255,255,0) 105%);

} */

.order-details ion-grid {
	padding-inline-start: 0;
	padding-inline-end: 0;
}

.order-details .okx-box-footer {
	border-top: 2px solid var(--okx-border-color);
	padding-left: 0;
	margin-left: 0;
	padding-right: 0;
	margin-right: 0;
}

.order-details .okx-box-footer ion-row {
	border-bottom: 0 none;
}

.order-details .okx-box-header-grid ion-row:not(:last-child) ion-col {
	padding: 5px 0px 0 0px;
}

.order-details ion-col .small-text {
	font-size: calc(var(--okx-small-text-font-size) - 2px);
}

.web #main > ion-content.route-order-completed::after {
	display: none;
}

.web #main > ion-content.route-order-completed > .ion-padding > .absolute-content.order-details {
	background: none;
	overflow-y: auto;
	padding: 0;
}
.web #main > ion-content.route-order-completed > .ion-padding > .absolute-content > div {
	padding: var(--ion-padding);
}
.web #main > ion-content.route-order-completed > .ion-padding > .absolute-content.order-details:after {
	border-radius: var(--okx-box-radius);
	box-shadow: 0 0 32px rgba(0, 0, 0, 0.25);
}

.web #main > ion-content.route-order-completed > .ion-padding > .absolute-content.order-details > div.scrollable-y {
	height: 100%;
}

.web .order-details .okx-box,
.web .order-details .okx-box-header {
	border-color: var(--okx-border-color);
	border-width: 0px;
}

.order-details .okx-box-header-grid ion-row:not(:last-child) ion-col {
	padding: 7px 0px 0 0px;
}

.order-details .okx-box-header-grid ion-row:last-of-type ion-col {
	padding: 7px 0 7px 0;
}

.web .order-details .okx-box-header-grid ion-row ion-col:nth-of-type(odd) strong {
	font-weight: normal;
}

.web .order-details .okx-box-header-grid ion-row ion-col:nth-of-type(even) {
	text-align: right;
	font-weight: bold;
}

.card-label {
	font-size: var(--okx-small-text-font-size) !important;
}
.order-content-details {
	display: flex;
	flex-direction: column-reverse;
	align-items: flex-start;
}
.order-content-details .small-text {
	line-height: 1.6;
}
.order-content-details > div:first-of-type {
	flex: 1;
}
.order-content-details .order-status-wrapper {
	margin-bottom: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.order-status-wrapper > div {
	padding: 2px 15px;
	background-color: var(--ion-color-primary);
	color: var(--ion-color-primary-contrast) !important;
	border-radius: 15px;
	text-transform: uppercase;
	font-size: var(--okx-small-text-font-size);
}
