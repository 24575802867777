/* ion-content div.restaurant-gradient {
	background-image: url(../../assets/images/click-n-collect-01.jpg);
	background-repeat: no-repeat;
	background-position: 50% 0;
	background-size: 200% auto;
} */

.restaurant-card {
  border-radius: var(--ion-card-radius);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  margin: 0;
  background: transparent;
}
.snoozed {
  text-decoration: line-through;
  text-decoration-color: var(--ion-color-gray-shade);
}
.click-collect-pickers-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  background-color: black;
  opacity: 0.3;
  height: 100%;
  width: 100%;
  z-index: 3;
}


.web ion-content.route-click-and-collect,
.web ion-content.route-click-and-collect-scheduled {
  --ion-color-base: transparent;
  /* --background: transparent url(../../assets/images/page-05.jpg) repeat-y 0 0 / 100% auto; */
}

.route-click-and-collect .absolute-content,
.route-click-and-collect-scheduled .absolute-content {
  padding: 0;
}
.route-click-and-collect .click-and-collect-title,
.route-click-and-collect-scheduled .click-and-collect-title {
  padding: var(--ion-padding);
  flex: unset;
}
.route-click-and-collect .click-and-collect-locations,
.route-click-and-collect-scheduled .click-and-collect-locations {
  flex: 1;
  overflow: auto;
  margin-bottom: 20px;
}
.route-click-and-collect .click-and-collect-time,
.route-click-and-collect-scheduled .click-and-collect-time {
  height: 70%;
}
.route-click-and-collect .click-and-collect-locations .box-wrapper,
.route-click-and-collect-scheduled .click-and-collect-locations .box-wrapper {
  padding: 0;
  margin: 0 var(--ion-padding) 10px;
}
.route-click-and-collect .click-and-collect-button-wrapper,
.route-click-and-collect-scheduled .click-and-collect-button-wrapper {
  padding: var(--ion-padding);
  flex: unset;
}
.route-click-and-collect .mbsc-select-input.mbsc-control,
.route-click-and-collect-scheduled .mbsc-select-input.mbsc-control {
  display: none !important;
}
.click-and-collect-timepicker-wrapper {
  margin: 0 var(--ion-padding);
  padding: 0 !important;
  overflow: hidden;
}
.click-collect-items ion-item {
  margin: 0 var(--ion-padding);
}
.striked {
  text-decoration: line-through;
}
.click-and-collect-timepicker-wrapper .mbsc-ios .mbsc-sc-itm {
  font-size: 1.5em !important;
}
