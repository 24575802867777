:root {
  --clickup-initial-height: 50px;
  /* --clickup-open-height: 50%; */
}

.pill {
  display: inline-block;
  border: 1px solid var(--ion-color-primary) !important;
  background-color: var(--ion-color-primary);
  color: var(--ion-color-primary-contrast) !important;
  font-size: var(--okx-small-text-font-size);
  padding: 5px 7px;
  margin: 0 5px 5px 0;
  text-transform: uppercase;
  border-radius: 15px;
}

.pill-action {
  cursor: pointer;
}
.pill-group{
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}