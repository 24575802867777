.web ion-content.route-split-bill-by-items {
	width: 100%;
	height: calc(100vh - 79px);
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
	overflow-y: hidden;
}
.route-split-bill-by-items ion-row.disabled,
.route-split-bill-by-items ion-row.disabled * {
	opacity: 0.7;
}
.table-items-to-pay-wrapper.box-wrapper {
	padding: 0;
	overflow: hidden;
}
.table-items-to-pay-wrapper > *:not(:last-of-type) {
	border-bottom: 1px solid var(--okx-box-wrapper-border);
}
.table-items-to-pay-wrapper > * > ion-item {
	border: none !important;
}
.subitem.pay-items {
	padding-left: 10px;
}
.table-items-to-pay-wrapper.box-wrapper ion-item {
	padding: 0 0 0 5px;
}

.table-items-to-pay-wrapper ion-checkbox {
	margin: 0 5px !important;
}
