.text-box {
	display: flex;
	justify-content: center;
	height: 40px;
	/* background: green; */
	align-items: center;
	font-weight: bolder;
}

.box-content .order-paid-status {
	padding: 2px 15px;
	background-color: var(--ion-color-primary);
	color: var(--ion-color-primary-contrast) !important;
	border-radius: 15px;
	width: max-content;
	margin-bottom: 5px;
	text-transform: uppercase;
	font-size: var(--okx-small-text-font-size);
}
