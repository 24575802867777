@import url('variables.css') screen;

/* .okx-font-primary {
  font-family: var(--okx-font-primary);
}
.okx-font-secondary {
  font-family: var(--okx-font-secondary);
}
.okx-font-tertiary {
  font-family: var(--okx-font-tertiary);
} */
* {
  box-sizing: border-box;
  font-family: var(--ion-font-family);
  /* line-height: 1.5; */
}
.ion-color-favorite {
  --ion-color-base: #69bb7b;
  --ion-color-base-rgb: 105, 187, 123;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: #5ca56c;
  --ion-color-tint: #78c288;
}

.ion-color-twitter {
  --ion-color-base: #1da1f4;
  --ion-color-base-rgb: 29, 161, 244;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: #1a8ed7;
  --ion-color-tint: #34aaf5;
}

.ion-color-google {
  --ion-color-base: #dc4a38;
  --ion-color-base-rgb: 220, 74, 56;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: #c24131;
  --ion-color-tint: #e05c4c;
}

.ion-color-vimeo {
  --ion-color-base: #23b6ea;
  --ion-color-base-rgb: 35, 182, 234;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: #1fa0ce;
  --ion-color-tint: #39bdec;
}

.ion-color-facebook {
  --ion-color-base: #3b5998;
  --ion-color-base-rgb: 59, 89, 152;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: #344e86;
  --ion-color-tint: #4f6aa2;
}

.ion-color-primary-shade {
  --ion-color-base: var(--ion-color-primary-shade);
  --ion-color-base-rgb: 185, 3, 11;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: var(--ion-color-primary-shade);
  --ion-color-tint: var(--ion-color-primary);
}

.ion-color-white {
  --ion-color-base: var(--ion-color-white);
  --ion-color-base-rgb: var(--ion-color-white-rgb);
  --ion-color-contrast: var(--ion-color-white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
  --ion-color-shade: var(--ion-color-white-shade);
  --ion-color-tint: var(--ion-color-white-tint);
}

.ion-color-black {
  --ion-color-base: var(--ion-color-black);
  --ion-color-base-rgb: var(--ion-color-black-rgb);
  --ion-color-contrast: var(--ion-color-black-contrast);
  --ion-color-contrast-rgb: var(--ion-color-black-contrast-rgb);
  --ion-color-shade: var(--ion-color-black-shade);
  --ion-color-tint: var(--ion-color-black-tint);
}

.ion-color-gray,
.ion-color-grey {
  --ion-color-base: var(--ion-color-gray);
  --ion-color-base-rgb: var(--ion-color-gray-rgb);
  --ion-color-contrast: var(--ion-color-gray-contrast);
  --ion-color-contrast-rgb: var(--ion-color-gray-contrast-rgb);
  --ion-color-shade: var(--ion-color-gray-shade);
  --ion-color-tint: var(--ion-color-gray-tint);
}

.ion-color-superlight {
  --ion-color-base: var(--ion-color-superlight);
  --ion-color-base-rgb: var(--ion-color-superlight-rgb);
  --ion-color-contrast: var(--ion-color-superlight-contrast);
  --ion-color-contrast-rgb: var(--ion-color-superlight-contrast-rgb);
  --ion-color-shade: var(--ion-color-superlight-shade);
  --ion-color-tint: var(--ion-color-superlight-tint);
}

.ion-color-success {
  --ion-color-base: var(--ion-color-success);
  --ion-color-base-rgb: var(--ion-color-success-rgb);
  --ion-color-contrast: var(--ion-color-success-contrast);
  --ion-color-contrast-rgb: var(--ion-color-success-contrast-rgb);
  --ion-color-shade: var(--ion-color-success-shade);
  --ion-color-tint: var(--ion-color-success-tint);
}

.ion-color-warning {
  --ion-color-base: var(--ion-color-warning);
  --ion-color-base-rgb: var(--ion-color-warning-rgb);
  --ion-color-contrast: var(--ion-color-warning-contrast);
  --ion-color-contrast-rgb: var(--ion-color-warning-contrast-rgb);
  --ion-color-shade: var(--ion-color-warning-shade);
  --ion-color-tint: var(--ion-color-warning-tint);
}

.ion-color-danger {
  --ion-color-base: var(--ion-color-danger);
  --ion-color-base-rgb: var(--ion-color-danger-rgb);
  --ion-color-contrast: var(--ion-color-danger-contrast);
  --ion-color-contrast-rgb: var(--ion-color-danger-contrast-rgb);
  --ion-color-shade: var(--ion-color-danger-shade);
  --ion-color-tint: var(--ion-color-danger-tint);
}

.okx-color-primary {
  color: var(--ion-color-primary);
}

.okx-color-secondary {
  color: var(--ion-color-secondary);
}

.okx-color-tertiary {
  color: var(--ion-color-tertiary);
}

.okx-color-gray {
  color: var(--ion-color-gray);
}

.okx-color-dark {
  color: var(--ion-color-dark);
}

.okx-bgcolor-primary {
  background-color: var(--ion-color-primary);
}

.okx-bgcolor-secondary {
  background-color: var(--ion-color-secondary);
}

.okx-bgcolor-tertiary {
  background-color: var(--ion-color-tertiary);
}

.okx-bordercolor-primary {
  border-color: var(--ion-color-primary);
}

.okx-bordercolor-secondary {
  border-color: var(--ion-color-secondary);
}

.okx-bordercolor-tertiary {
  border-color: var(--ion-color-tertiary);
}

/* Shared Variables
// --------------------------------------------------
// To customize the look and feel of this app, you can override
// the CSS variables found in Ionic's source files.
// To view all the possible Ionic variables, see:
// https://beta.ionicframework.com/docs/theming/css-variables#ionic-variables
*/

/* ion reset */

.list-ios {
  margin-bottom: 0;
}

ion-modal ion-page,
ion-modal .ion-page {
  min-width: 100px;
}

/* end ion reset */

.full-height {
  height: 100%;
}

h1 {
  display: block;
  color: lightgray;
  position: relative;
  text-decoration: underline;
  text-decoration-color: red;
}

h1::after {
  display: block;
  content: "DON'T USE H1 TAG ! ! !";
  color: red;
}

h2,
h3,
h4,
h5,
h6 {
  display: block;
  margin: 0;
}

p,
div,
li,
ion-item,
.strong-text,
.normal-text {
  font-size: var(--ion-font-size);
}

p,
li,
.strong-text,
.normal-text {
  color: var(--okx-box-wrapper-color);
}
.block {
  display: block;
}
.break-space {
  white-space: normal !important;
}
.strong-text {
  font-family: var(--ion-font-family-bold);
  font-weight: bold;
}

strong {
  font-family: var(--ion-font-family-bold);
}

.normal-text {
  font-weight: normal !important;
  font-family: var(--ion-font-family-medium);
}

.small-text {
  font-size: var(--okx-small-text-font-size);
  color: var(--okx-box-wrapper-color);
  font-family: var(--ion-font-family);
  font-weight: 400;
}
.uppercase {
  text-transform: none !important;
}
.light-text {
  color: var(--okx-light-text-color);
}
ion-input {
  overflow: hidden;
}
ion-input,
ion-select,
ion-datetime,
.sc-ion-input-md-h,
.sc-ion-input-ios-h,
.datetime-text,
.masked-input {
  --color: var(--okx-textfield-color);
  color: var(--okx-textfield-color);
}
input:-webkit-autofill {
  -webkit-text-fill-color: var(--okx-textfield-color) !important;
}
ion-datetime.datetime-placeholder {
  opacity: 0.33;
}

.big-label {
  font-size: var(--okx-big-label-font-size);
  font-weight: bold;
  color: var(--okx-big-label-color);
  padding: var(--okx-big-label-padding);
  margin: var(--okx-big-label-margin);
}

.title {
  line-height: 1.3;
  font-size: var(--okx-title-font-size);
  color: var(--ion-color-primary);
  font-family: var(--ion-font-family-bold);
  font-weight: normal;
  text-transform: var(--text-transform-title);
  margin-bottom: 10px !important;
}

.subtitle,
h2,
h3,
h4,
h5,
h6 {
  font-size: var(--okx-subtitle-font-size);
  color: var(--okx-box-wrapper-color);
  /* margin: var(--okx-subtitle-margin); */
}

ion-label h2,
ion-label h3,
ion-label h4,
ion-label h5,
ion-label h6 {
  font-size: var(--okx-sectiontitle-font-size) !important;
}

.sectiontitle {
  font-size: var(--okx-sectiontitle-font-size);
  color: var(--okx-sectiontitle-color);
  padding: var(--okx-sectiontitle-padding);
  margin: 0;
}

.medium-font-size {
  font-size: var(--okx-medium-font-size);
}

.title:first-child,
.subtitle:first-child,
.sectiontitle:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child {
  padding-top: 0;
  margin-top: 0;
}

.padded {
  padding: var(--ion-padding);
}

.padded-small {
  padding: var(--okx-padding-small);
}

.padded-tiny {
  padding: var(--okx-padding-tiny);
}

.padded-top {
  padding-top: var(--ion-padding);
}

.padded-bottom {
  padding-bottom: var(--ion-padding);
}

.padded-left {
  padding-left: var(--ion-padding);
}

.padded-right {
  padding-right: var(--ion-padding);
}

.margined {
  margin: var(--okx-margin);
}

.margined-top {
  margin-top: var(--okx-margin);
}

.margined-bottom {
  margin-bottom: var(--okx-margin);
}

.margined-right {
  margin-right: var(--okx-margin);
}

.no-margin {
  margin: 0;
}

.no-margin-bottom {
  margin-bottom: 0;
}

.no-margin-top {
  margin-top: 0;
}

.no-padding-top {
  padding-top: 0;
}

.no-padding-bottom {
  padding-bottom: 0;
}

.no-padding-left {
  padding-left: 0;
}

.no-padding-right {
  padding-right: 0;
}

.no-padding {
  padding: 0;
}

ion-grid.no-padding {
  --ion-grid-padding: 0;
}

ion-grid.no-padding ion-col {
  padding: 0;
}

.md {
  --ion-toolbar-background: var(--ion-color-white);
  --ion-toolbar-color: #fff;
  --ion-toolbar-color-activated: #fff;
}

.bold {
  font-weight: bold !important;
}
.italic {
  font-style: italic;
}
/*
.pad10 {
	padding: 10px;
}

.pad20 {
	padding: 20px;
}

.pad40b {
	padding-bottom: 40px;
}

.pad5l {
	padding-left: 5px;
}

.pad17l {
	padding-left: 17px;
}
*/
.box-holder ion-item {
  --padding-start: 0px;
}
.box-holder ion-item:first-child {
  border-bottom: 1px solid var(--ion-color-gray);
}
.single-item {
  margin-bottom: 10px;
}

ion-label h2 {
  color: var(--ion-color-form-label-dark);
  font-weight: 500;
}

ion-note {
  color: var(--ion-color-secondary);
  font-size: var(--okx-small-text-font-size);
  font-weight: 400;
}
ion-item.item-disabled {
  opacity: 1 !important;
}
ion-item.item-disabled > * {
  opacity: 0.5 !important;
}
ion-item {
  --padding-start: 0;
  --inner-padding-end: 0;
  /* --background-hover:transparent; */
}

ion-item ion-button {
  margin-bottom: 0;
  height: auto;
}

.float-input-holder {
  --padding-start: 0px;
  --border-color: var(--ion-color-form-label);
}

.top-small {
  margin-top: var(--ion-top-small);
}

.top-medium {
  margin-top: var(--ion-top-medium);
}

.verified-content {
  width: 190px;
  margin: 0 auto;
  text-align: center;
}

ion-button {
  height: 42px;
  --border-radius: 3px;
  --box-shadow: none;
  margin-bottom: 10px;
  text-transform: none;
  letter-spacing: normal;
  font-weight: 400;
  --background-hover: transparent;
  --background-activated: transparent;
  --background-focused: transparent;
  --background-activated-opacity: 0;
  --background-focused-opacity: 0;
  --background-hover-opacity: 0;
}
ion-item {
  --background-hover: transparent !important;
  --background-activated: transparent !important;
  --background-focused: transparent !important;
  --background-activated-opacity: 0 !important;
  --background-focused-opacity: 0 !important;
  --background-hover-opacity: 0 !important;
  --ripple-color: transparent;
}
ion-button.validation {
  --background: var(--okx-color-white);
  --color: var(--ion-color-primary);
}

ion-button.ion-color-primary {
  --border-style: solid;
  --border-width: 2px;
  --border-color: var(--ion-color-primary);
}
ion-button.ion-color-primary svg {
  height: 20px;
  width: 20px;
  stroke: var(--ion-color-primary-contrast);
  margin-right: 5px;
}
ion-button.ion-color-white {
  --border-style: none;
  --border-width: 0;
  --border-color: transparent;
}

ion-button.cancel {
  --background: var(--okx-cancel-button-color) !important;
  --color: var(--ion-color-primary);
}

ion-button.link {
  --background: var(--okx-cancel-button-color) !important;
  --color: inherit;
  font-weight: 500;
  cursor: pointer;
  --background-hover: red !important;
}

ion-button.rounded {
  --border-radius: 60px;
}

ion-button.button-small {
  font-size: var(--okx-small-text-font-size) !important;
}
ion-button.link.underlined {
  font-size: var(--ion-font-size) !important;
}
ion-header ion-title {
  text-transform: uppercase;
  font-weight: bold;
}

.pov,
.poo,
.relative {
  position: relative;
}

.mixed-right,
.mixed-right * {
  text-align: right;
  justify-content: flex-end;
  align-items: center;
  align-self: center;
  justify-self: flex-end;
}

.mixed-left,
.mixed-left * {
  text-align: left;
  justify-content: flex-start;
  align-items: center;
  align-self: center;
  justify-self: flex-start;
}

.lefted,
.lefted * {
  text-align: left;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: flex-start;
  justify-self: flex-start;
}

.mixed-left,
.mixed-left * .grow {
  flex-grow: 0.5;
}

.contrast-separator {
  display: block;
  height: 0;
  line-height: 0;
  border: 1px solid rgba(255, 255, 255, 0.3);
  margin: 16px 0;
}

.contrast-separator.contrast-separator-mini {
  margin: 12px 0;
}

.poster {
  padding-top: 65%;
  background-color: transparent;
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.text-link {
  --ion-toolbar-background: transparent;
  --background-hover: transparent;
  --background-activated: transparent;
  --background-focused: transparent;
  --color: var(--ion-color-gray);
  --color-hover: var(--ion-color-dark);
  --color-activated: var(--ion-color-dark);
  --color-focused: var(--ion-color-dark);
  --ripple-color: #fff;
  text-transform: unset;
  font-size: inherit;
}

span.link {
  color: inherit;
  cursor: pointer;
  font-weight: 500;
}

span.link.link-primary {
  color: var(--ion-color-primary);
  cursor: pointer;
}

.paddL {
  padding-left: 0;
}

.paddR {
  padding-right: 0;
}

.paddLR {
  padding-right: 0px;
  padding-left: 0px;
}

ion-button.link,
ion-buttons ion-button.link,
.sc-ion-buttons-md-h.ion-color.sc-ion-buttons-md-s .button.link,
.ion-color .sc-ion-buttons-md-h.sc-ion-buttons-md-s .button.link,
.sc-ion-buttons-ios-h.ion-color.sc-ion-buttons-ios-s .button.link,
.ion-color .sc-ion-buttons-ios-h.sc-ion-buttons-ios-s .button.link {
  --padding-start: 0;
  --padding-end: 0;
  --padding-top: 0;
  --padding-bottom: 0;
  --background: transparent;
  --background-active: transparent;
  --background-focused: transparent;
  --background-hover: transparent;
  --border-color: transparent;
  --border-style: none;
  --border-width: 0;
  --box-shadow: none;
  --ripple-color: transparent;
  --color: var(--ion-color-primary);
  --color-hover: var(--ion-color-primary-shade);
  --color-focused: var(--ion-color-primary-shade);
  --color-activated: var(--ion-color-primary-shade);
  font-size: inherit;
}

.segment-holder {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
}

.segment-holder ion-segment {
  flex: 0 0 auto;
}

.segment-holder .segment-content {
  flex: 1 1 auto;
  overflow: hidden;
  overflow-y: auto;
}

.scrollable {
  overflow: auto;
}

.scrollable-y {
  overflow: hidden;
  overflow-y: auto;
}

.scrollable-x {
  overflow: hidden;
  overflow-x: auto;
}

.bordered,
.bordered-top,
.bordered-bottom,
.bordered-left,
.bordered-right {
  border: 1px solid var(--okx-border-color);
}

.bordered-primary {
  border: 1px solid var(--ion-color-primary);
}

.bordered-top {
  border-width: 1px 0 0;
}

.bordered-bottom {
  border-width: 0 0 1px;
}

.bordered-left {
  border-width: 0 0 0 1px;
}

.bordered-right {
  border-width: 0 1px 0 0;
}

.underlined {
  text-decoration: underline;
}

ion-segment-button {
  height: 50px;
  border-radius: 0px;
  color: var(--ion-color-dark);
  border: 0 none;
  font-weight: bold;
}

ion-segment-button.segment-button-checked {
  border-bottom-color: var(--ion-color-primary);
}

.clickable {
  cursor: pointer;
}

.field-error {
  color: var(--ion-color-danger);
  font-size: var(--okx-small-text-font-size);
  margin: var(--okx-error-margin);
}

ion-checkbox {
  --checkmark-color: #fff;
}
.flex-wrapper,
.flex-col-wrapper,
.flex-col-holder,
.flex-row-wrapper,
.flex-row-holder {
  display: flex;
}

.flex-col-wrapper,
.flex-col-holder {
  flex-direction: row;
}

.flex-col-wrapper.flex-reverse,
.flex-col-holder.flex-reverse {
  flex-direction: row-reverse;
}

.flex-row-wrapper,
.flex-row-holder {
  flex-direction: column;
}

.flex-row-wrapper.flex-reverse,
.flex-row-holder.flex-reverse {
  flex-direction: column-reverse;
}

.flex-col-wrapper > div,
.flex-col-holder > div,
.flex-row-wrapper > div,
.flex-row-holder > div {
  flex: 1 1 auto;
}

.flex-col-wrapper > div.flex-min,
.flex-col-holder > div.flex-min,
.flex-row-wrapper > div.flex-min,
.flex-row-holder > div.flex-min {
  flex: 0 1 var(--okx-flex-min);
}

.flex-col-wrapper > div.flex-optional,
.flex-col-holder > div.flex-optional,
.flex-row-wrapper > div.flex-optional,
.flex-row-holder > div.flex-optional {
  flex: 1 1 0;
}

.flex-col-wrapper > div.flex-spacer,
.flex-col-holder > div.flex-spacer,
.flex-row-wrapper > div.flex-spacer,
.flex-row-holder > div.flex-spacer {
  flex: 0 1 var(--okx-flex-spacer);
}

.flex-align-start {
  align-items: flex-start;
}

.flex-justify-start {
  justify-content: flex-start;
}

.flex-align-center {
  align-items: center;
}

.flex-justify-center {
  justify-content: center;
}

.flex-align-end {
  align-items: flex-end;
}

.flex-justify-end {
  justify-content: flex-end;
}

.flex-align-stretch {
  align-items: stretch;
}

.flex-justify-stretch {
  justify-content: stretch;
}

.flex-justify-between {
  justify-content: space-between;
}

.flex-justify-around {
  justify-content: space-around;
}

.absolute-content {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  padding-inline-start: var(--ion-padding, 16px);
  padding-inline-end: var(--ion-padding, 16px);
  padding-left: var(--ion-padding, 16px);
  padding-right: var(--ion-padding, 16px);
  padding-top: 5px;
  padding-bottom: var(--ion-padding, 16px);
}

.web .title.web-only {
  color: var(--ion-color-primary);
}
.web .absolute-content {
  padding-top: var(--ion-padding, 16px);
}
.absolute-content > div {
  position: relative;
  z-index: 1;
}

.centered,
.centered * {
  text-align: center;
  justify-content: center;
  align-items: center;
  align-self: center;
  justify-self: center;
}

.righted,
.righted * {
  text-align: right;
  justify-content: flex-end;
  align-items: flex-end;
  align-self: flex-end;
  justify-self: flex-end;
}

.righted,
.righted * .grow {
  flex-grow: 0;
}

.righted,
.righted * .instruction-grow {
  flex-grow: 1;
}

.lefted,
.lefted * {
  text-align: left;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: flex-start;
  justify-self: flex-start;
}

.self-aligned {
  align-self: center;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.break-spaces {
  white-space: break-spaces;
}
.uppercase {
  text-transform: uppercase;
}

.hidden,
.web-only {
  display: none;
}

.invisible {
  visibility: hidden;
}

hr {
  color: transparent;
  background-color: transparent;
  height: 0;
  min-height: 0;
  line-height: 0;
  margin: var(--okx-hr-margin);
  border: 0 none;
  border-top: 2px solid var(--ion-color-gray);
}

ion-badge.small {
  font-size: 7px;
  --padding-start: 2px;
  --padding-end: 2px;
  --padding-top: 2px;
  --padding-bottom: 2px;
  border-radius: 50%;
  min-width: 11px;
  text-transform: none;
  position: absolute;
}

.card-thumb {
  display: inline-block;
  width: 55px;
  height: 35px;
  /*background-color: var(--ion-color-light);*/
  position: relative;
  border-radius: 3px;
  border: 1px solid var(--ion-color-light);
}

.card-thumb.visa::after {
  display: block;
  position: absolute;
  left: 0;
  top: 10px;
  right: 0;
  bottom: 0;
  content: 'VISA';
  color: #2566af;
  text-align: center;
  font-weight: 900;
  font-style: italic;
}

.card-thumb.mc::before {
  display: block;
  position: absolute;
  left: 5px;
  top: 5px;
  width: 26px;
  height: 26px;
  content: '';
  text-shadow: 1px 1px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  background-color: #c00;
}

.card-thumb.mc::after {
  display: block;
  position: absolute;
  left: 0;
  top: 2px;
  right: 0;
  bottom: 0;
  content: 'MasterCard';
  color: #fff;
  font-size: 8px;
  padding-top: 11px;
  text-align: center;
  font-weight: bold;
  font-style: italic;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.3);
  /*background-image: radial-gradient(circle at 36px 16px, #f90 0px 12px,transparent 13px);*/
  background-image: radial-gradient(
    circle at 36px 16px,
    rgba(255, 153, 0, 0.7) 0px 12px,
    transparent 13px
  );
}

.title-bar,
.delivery-option-label {
  padding: 12px 0;
}

.delivery-option-label {
  position: relative;
}

.title-bar .title,
.title-bar .subtitle,
.delivery-option-label .title,
.delivery-option-label .subtitle {
  margin: 0;
}

ion-item.item-label-floating.item-has-focus ion-label,
ion-item.item-label-floating.item-has-value ion-label {
  transform: translate3d(0, 50%, 0) scale(0.8) !important;
}
.no-borders {
  --border-style: none !important;
}
.restaurant-card ion-card-content {
  padding: var(--ion-padding);
  font-size: var(--ion-font-size);
}
.web .mobile-only {
  display: none;
}
ion-button {
  --border-radius: 20px;
  --box-shadow: none;
  margin-bottom: 10px;
  text-transform: none;
  letter-spacing: normal;
  font-size: var(--ion-font-size) !important;
  font-family: var(--ion-font-family-bold) !important;

  /* text-transform: uppercase !important; */
}

ion-button.small-btn {
  --padding-start: 10px;
  --padding-end: 10px;
  --padding-top: 5px;
  --padding-bottom: 5px;
  height: max-content;
}
.restaurant-card ion-card-content .restaurant-info-title {
  padding: 0 2px;
  font-weight: bold;
}

.restaurant-card ion-card-content .restaurant-info {
  padding: 15px 15px 30px;
  text-align: center;
}

input:-webkit-autofill,
textarea:-webkit-autofill,
input:-webkit-autofill:hover,
textarea:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

.mbsc-material .mbsc-sc-itm,
.mbsc-material .mbsc-sc-itm-sel,
.mbsc-sc-itm,
.mbsc-sc-itm-sel,
.mbsc-ios .mbsc-sc-itm,
.mbsc-ios .mbsc-sc-itm-sel {
  background: transparent !important;
}
.pointer {
  cursor: pointer;
}
.web .ion-padding,
.web #main > ion-content.route-history > .no-padding,
.web .ion-padding,
.web #main > ion-content.route-history > .no-padding,
.web .raf-card,
.web .restaurant-card,
.web .delivery-options-card,
.web #main > ion-content.route-loyalty > .no-padding {
  position: absolute !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  top: 0 !important;
  margin: auto !important;
  background-color: var(--okx-background-color) !important;
  border-radius: var(--okx-box-radius) !important;
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.25) !important;
  max-height: var(--okx-modal-height) !important;
  overflow: hidden !important;
  width: var(--okx-bsmodal-width-lg);
}

.unclicked {
  pointer-events: none !important;
}

.without-padding {
  padding: 0 !important;
}
.inline-input-field-wrapper > div::after {
  pointer-events: none;
  content: '\25bc';
  height: 100%;
  width: 15px;
  position: absolute;
  right: 10px;
  top: 0;
  bottom: 0;
  font-size: 15px;
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  margin-left: 5px;
  padding-left: 5px;
  color: var(--okx-textfield-color);
}
.inline-input-field-wrapper > div {
  max-width: 90px;
  position: relative;
}
.inline-input-field-wrapper ion-input {
  padding-left: var(--okx-padding) !important;
}
.input-field-wrapper.item-has-focus {
  border: 1px solid var(--ion-color-primary) !important;
}
.input-field-wrapper.item-has-focus ion-input {
  --background: transparent !important;
}

.input-field-wrapper.dropdown-field {
  position: relative;
}
.input-field-wrapper.dropdown-field::after {
  content: '\203A';
  height: 100%;
  width: 20px;
  position: absolute;
  right: 10px;
  top: 0;
  bottom: 0;
  font-size: 20px;
  display: flex;
  margin: auto;
  color: var(--okx-textfield-color);
  align-items: center;
  transform: rotate(90deg);
  justify-content: center;
  font-weight: bold;
}
.input-field-wrapper,
.web .input-field-wrapper {
  padding: 0 10px;
  background-color: var(--okx-textfield-background);
  border-radius: var(--okx-box-radius);
  border: 1px solid var(--okx-textfield-border);
  margin: 0 0.5px;
}

.input-field-two-columns-wrapper.input-field-wrapper {
  display: flex;
  padding: 0;
  margin-top: 10px;
}
.input-field-two-columns-wrapper.input-field-wrapper > div {
  padding: 10px;
}
.input-field-two-columns-wrapper.input-field-wrapper > div:first-child {
  border-right: 1px solid var(--okx-textfield-border);
  width: 33%;
}
.input-field-wrapper input,
.input-field-wrapper ion-input {
  height: 100%;
  border: none;
  outline: none;
  background-color: transparent;
}
.input-field-wrapper ion-textarea {
  margin: 0;
  padding-inline-start: 0;
  --padding-start: 0;
}
.disabled-wrapper *,
.disabled-wrapper {
  pointer-events: none;
}
.disabled-wrapper,
.web .disabled-wrapper {
  opacity: 0.4;
}
.input-field-container {
  margin-bottom: 10px;
}
.input-field-container.no-margin {
  margin-bottom: 0;
}
.input-field-container .normal-text {
  margin-bottom: 3px;
  color: var(--okx-box-wrapper-color) !important;
  display: block;
  text-align: start;
}
.input-field-container .normal-text.primary-color {
  color: var(--ion-color-primary) !important;
}
.input-field-container-multiple > *:not(:last-of-type) {
  margin-bottom: 10px;
}
.primary-color {
  color: var(--ion-color-primary) !important;
}
.danger-color,
.danger-color * {
  color: var(--ion-color-danger) !important;
}
.warning-color,
.warning-color * {
  color: var(--ion-color-warning) !important;
}
.success-color,
.success-color * {
  color: var(--ion-color-success) !important;
}
.light-color {
  opacity: 0.6;
}
.secondary-color {
  color: var(--ion-color-secondary) !important;
}
ion-app:not(.web) {
  background-color: var(--okx-background-color);
}
.segment-holder ion-segment {
  background-color: var(--okx-background-color) !important;
}
.box-with-shadow {
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.1);
}
.box-content {
  background-color: var(--okx-box-wrapper-background) !important;
  border-radius: var(--okx-box-radius);
  padding: 10px;
}
.box-content--loading > div ion-row {
  opacity: 0.4;
  position: relative;
}
.box-content--loading ion-spinner {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  color: var(--ion-color-primary) !important;
}
.box-wrapper.required-field {
  border: 1px solid var(--ion-color-danger);
}
.box-wrapper.space-between {
  padding: 10px;
}
.box-wrapper.required-field * {
  color: var(--ion-color-danger) !important;
}
.box-wrapper {
  background-color: var(--okx-box-wrapper-background) !important;
  border-radius: var(--okx-box-radius);
  /* border: 1px solid var(--ion-color-gray); */
  padding: 0;
}
.box-wrapper ion-item {
  padding: 10px;
}
.box-wrapper ion-item ion-label {
  margin: 0 !important;
}
.box-wrapper > *:not(:last-child) > ion-item,
.box-wrapper ion-item:not(:last-child) {
  border-bottom: 1px solid var(--ion-color-gray);
}
.box-wrapper:not(.time-picker-wrapper):not(.required-field) *,
.box-content * {
  color: var(--okx-box-wrapper-color);
}

.box-wrapper.time-picker-wrapper {
  border: none;
  flex: unset;
}
.box-wrapper-multiple-items {
  border-radius: var(--okx-box-radius);
  padding: 0;
  margin-top: 5px;
  background-color: var(--okx-box-wrapper-background);
}
.box-wrapper-multiple-items > * {
  padding: 10px;
  color: var(--okx-box-wrapper-color);
}
.box-wrapper-list {
  padding: 0 !important;
}
.box-wrapper-list > * {
  padding: 5px;
  border-bottom: 1px solid var(--okx-box-wrapper-border);
}
.box-wrapper-list > *:last-child {
  border-bottom: none;
}
.box-wrapper-multiple-items > *:not(:first-child) {
  border-top: 1px solid var(--okx-box-wrapper-border);
}
.box-wrapper.inline-box-wrapper {
  display: flex;
  align-items: center;
  height: 50px;
}
.box-wrapper.inline-box-wrapper > * {
  height: 50px;
  padding: 5px;
  display: flex;
  align-items: center;
}
.box-wrapper.inline-box-wrapper input {
  background-color: transparent !important;
}
.inline-box-wrapper > div:first-child {
  border-right: 1px solid var(--okx-box-wrapper-border);
  width: 50px;
  justify-content: center;
}
.inline-box-wrapper > ion-item {
  flex: 1;
}
.unselectable {
  pointer-events: none;
}
ion-checkbox,
ion-radio {
  --border-radius: 3px;
  --checkmark-color: #fff;
  height: 20px;
  width: 20px;
  --inner-border-radius: 3px;
  margin: 0 20px 0 0;
}
ion-radio {
  width: 20px;
  height: 20px;
  border-radius: 3px;
  margin: 0 5px 0 0;
  margin-right: 20px;
}
ion-checkbox {
  --border-color: var(--ion-color-primary);
}
ion-checkbox.checkbox-disabled {
  opacity: 0.5;
}
.radio-icon {
  contain: none;
}
ion-radio.radio-checked {
  background-color: var(--ion-color-primary) !important;
  --border-width: 0px;
  border: none;
}
.web ion-radio.radio-checked {
  --border-width: 2px;
}
.web ion-radio.radio-checked {
  background-color: transparent !important;
}
.web ion-radio {
  border: none;
}
.search-box-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 3px 25px 30px 0;
  background-color: var(--okx-background-color);
}
.search-box-wrapper .title {
  margin: 0 !important;
}
.web .search-box {
  position: relative;
  padding: 0;
  position: sticky;
  z-index: 5;
  top: 0;
  margin: 5px var(--ion-padding);
  width: 30%;
  background-color: transparent;
  border-radius: 0;
}
.search-box {
  position: absolute;
  top: 0;
  background-color: var(--ion-color-gray);
  margin-top: 0;
  width: calc(100% - var(--ion-padding) - var(--ion-padding));
  padding: 15px;
  left: var(--ion-padding);
  z-index: 3;
  border-radius: 15px 0 15px 15px;
}
.search-box > div {
  position: relative;
}
.search-box ion-icon {
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 1px;
  left: 10px;
  fill: var(--okx-textfield-color);
  z-index: 6;
}
.search-box ion-icon.reset-icon {
  left: unset;
  right: 10px;
  cursor: pointer;
}
.search-box ion-input {
  --padding-start: 20px;
  --padding-end: 20px;
  --padding-top: 0;
  --padding-bottom: 0;
  width: 100%;
  --background: var(--okx-textfield-background);
  border: 1px solid var(--okx-textfield-border);
  border-radius: var(--okx-box-radius);
  --border-width: 0;
}

.search-box ion-input input {
  border: none;
}
.disclaimer-wrapper,
.order-disclaimer-wrapper {
  flex: unset !important;
  margin: auto;
}

.order-disclaimer-wrapper img,
.disclaimer-wrapper img {
  width: 100%;
  object-position: center;
  object-fit: contain;
}
.dashboard-disclaimer-wrapper {
  position: fixed;
  bottom: -7px;
  left: 0;
  width: 100%;
}
.dashboard-disclaimer-wrapper img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  object-position: bottom;
}
.disabled-element {
  pointer-events: none;
  position: relative;
}
button.disabled-element {
  opacity: 0.4;
}
.disabled-element > * > * {
  opacity: 0.2;
}
.web .disabled-element > * {
  opacity: 0.2;
}
.web .disabled-element > * > * {
  opacity: 1;
}
ion-toolbar {
  --padding-end: var(--ion-padding) !important;
  --padding-start: var(--ion-padding) !important;
  --padding-bottom: 0 !important;
  --padding-top: 15px !important;
  --min-height: 40px !important;
}

ion-toolbar ion-button,
ion-toolbar ion-buttons,
ion-toolbar ion-menu-button {
  --padding-end: 1px !important;
  --padding-start: 1px !important;
  margin: 0 !important;
  font-size: 31px;
  /* height: 60px; */
}
ion-menu ion-buttons {
  font-size: unset;
  height: 40px !important;
  width: 40px !important;
  font-weight: 400;
}
ion-toolbar ion-buttons,
.header-title,
.image-button {
  padding-bottom: 15px;
}
ion-toolbar ion-button,
ion-toolbar ion-buttons {
  width: 32px;
}
ion-toolbar ion-buttons.image-button-wrapper {
  width: 100%;
}
.web ion-toolbar ion-buttons.image-button-wrapper {
  pointer-events: none !important;
}
ion-toolbar ion-buttons.button-active {
  margin-left: -1px !important;
  border-radius: 10px 10px 0 0;
  background-color: var(--ion-color-gray);
}

.is-uppercase {
  text-transform: uppercase;
}
ion-alert {
  --background: var(--okx-box-wrapper-background) !important;
  --max-width: 280px;
}
ion-alert .alert-title,
ion-alert .alert-message * {
  color: var(--okx-box-wrapper-color) !important;
}
.block-separator {
  display: flex;
  justify-content: center;
  position: relative;
}
.block-separator::before {
  width: 100%;
  height: 2px;
  background-color: var(--ion-color-gray);
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: -1;
}
.block-separator .normal-text {
  display: block;
  padding: 0 20px;
  background-color: var(--okx-background-color);
}
.block-separator {
  margin: 15px 0;
}
.click-collect-pickers-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  background-color: black;
  opacity: 0.3;
  height: 100%;
  width: 100%;
  z-index: 3;
}

.error-overlay {
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 10;
}

.default-padding {
  padding: 0 var(--ion-padding);
}
.default-margin {
  margin: 0 var(--ion-padding);
}
.restaurant-data {
  margin-top: 5px;
}
.restaurant-data svg {
  height: 15px;
  width: 15px;
  margin-right: 7px;
}
.restaurant-data p {
  margin: 0;
  font-size: var(--ion-font-size);
  color: var(--okx-box-wrapper-color);
}
.restaurant-data > div {
  display: flex;
  align-items: center;
}

.tiled-box-wrapper ion-checkbox {
  display: none;
}
.tiled-box-wrapper img {
  width: 100%;
  max-height: 110px;
  object-fit: cover;
}
.tiled-box-wrapper ion-item,
.tiled-box-wrapper.box-wrapper {
  border: none !important;
  background-color: transparent !important;
  padding: 0 !important;
  border-radius: 0;
}
.tiled-box-wrapper ion-item {
  margin-bottom: 15px;
}

.web .click-collect-dialog.show-up {
  width: var(--okx-bsmodal-width-lg);
  height: var(--okx-modal-height);
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}
.click-collect-dialog {
  position: fixed;
  margin: auto;
  background-color: #fff;
  width: 100%;
  height: 100%;
  border-radius: 0;
  top: 0%;
  left: 0%;
  transition: all 0.3s ease-out;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  z-index: 999;
}
.web .click-collect-dialog {
  border-radius: var(--okx-box-radius);
}
.click-collect-dialog.show-up {
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
}

.click-collect-dialog-layout {
  height: 100%;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  padding: var(--ion-padding);
  background-color: var(--okx-background-color);
  border-radius: var(--okx-box-radius);
}

.click-collect-dialog.sc-ion-modal-md {
  border-radius: var(--border-radius);
  width: var(--width);
  min-width: var(--min-width);
  max-width: var(--max-width);
  height: var(--height);
  min-height: var(--min-height);
  max-height: var(--max-height);
  border-width: var(--border-width);
  border-style: var(--border-style);
  border-color: var(--border-color);
  background: var(--background);
  -webkit-box-shadow: var(--box-shadow);
  box-shadow: var(--box-shadow);
  overflow: var(--overflow);
  z-index: 10;
}

.click-collect-dialog-header {
  flex: 0 1 auto;
  padding: 0;
}

.click-collect-dialog-header h3 {
  font-size: var(--okx-title-font-size);
  font-weight: bold;
  margin: 0;
}

.click-collect-dialog-closer {
  position: absolute;
  top: var(--ion-padding) !important;
  right: var(--ion-padding) !important;
  padding: 0;
  cursor: pointer;
}

.click-collect-dialog-closer ion-icon {
  font-size: 20px;
  fill: var(--ion-color-secondary);
}

.click-collect-dialog-content {
  flex: 1 1 auto;
  padding: 0;
  margin: 15px 0;
  overflow-y: auto;
}
